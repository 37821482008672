.rich-text-container {
  position: relative;
  width: 800px;
  margin: 0 auto 100px auto;

  .rich-text-save {
    position: absolute;
    top: 15px;
    left: 10px;
  }

  .button-position-back {
    position: absolute;
    top: -20px;
    right: 0px;
  }

  .button-position-next {
    position: absolute;
    top: 30px;
    right: 0px;
  }
  .demo-wrapper {
    // width: 800px;
    // margin: 0 auto;
    &.fixed {
      .rdw-editor-toolbar {
        position: fixed;
        left: 0px;
        top: 50px;
        background: #fff;
        transform: translateX(-50%);
        margin-left: 50%;
        z-index: 100;
        padding: 10px 110px 10px 110px;
      }
    }

    .rdw-editor-toolbar {
      justify-content: flex-start !important;
      margin: 0 auto;
      padding-left: 50px;
      width: 550px;
      background: transparent;
      border: none;

      .rdw-option-wrapper {
        border: 1px solid rgb(172, 172, 172);
      }

      .rdw-inline-editor {
        // margin-top: 100px;
      }
    }
  }

  .demo-editor {
    border: 1px solid #dae0e6;
    background-color: white;
    box-shadow: #c4c7cc 0px 0px 2px;
    border-radius: 3px;
    padding: 50px 75px;
    min-height: 500px;
  }
}
