.resume-preview-container {
  form {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 800px;
    margin: 0 auto;

    .publish-btn {
      margin-top: 50px;
    }
  }

  .resume-link {
    margin: 0;
  }

  .divider {
    width: 300px;
    height: 1px;
    margin-top: 50px;
    background: rgba(0, 0, 0, 0.1);
  }
}
