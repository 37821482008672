@import url('https://fonts.googleapis.com/css?family=Merienda|Tinos&display=block');
/* 'Tinos' */
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,400i,700&display=block');

// figure out how to do inheritance and extend
// didn't work when I tried
@keyframes fade-in-loading {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

html {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 55px 0px 0px 0px;
  min-width: 950px;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f8f8;

  @media screen and (max-width: 800px) {
    padding: 0px;
    min-width: initial;
  }
}

.base-container {
  border: 1px solid #dae0e6;
  background-color: white;
  box-shadow: #c4c7cc 0px 0px 2px;
  border-radius: 3px;
  width: 800px;
  min-height: 350px;
  margin: 0 auto;
  padding: 25px;
}

.base-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 55px);
  opacity: 0;
  animation: 1s fade-in-loading;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

.base-header-styling {
  color: #2f3233;
  text-align: center;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 52px;
  margin: 30px 0px 50px 0px;
}

.base-info-list {
  width: 500px;
  margin: 20px auto;
  list-style: none;
  background: #ffeacf;
  padding: 15px;
  padding-left: 25px;
  border-radius: 3px;
  box-shadow: 0 -3px 0 0 #ffc87f inset;
  padding-bottom: 10px;

  &.bring-up {
    margin-top: -30px;
    margin-bottom: 20px;
  }

  &.purchase {
    margin: 50px auto 20px auto;
  }

  & > li {
    position: relative;
    font-size: 20px;
    padding: 10px 10px 10px 10px;
    &:before {
      position: absolute;
      top: 17px;
      left: -6px;
      content: '';
      z-index: 10;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      margin: 0 0.5rem 0 0;
      background: #ff8a00;
    }
  }

  .base-info-list {
    box-shadow: none;
    margin: 0;
    width: auto;
    padding-bottom: 0px;
    li {
      &:before {
        top: 20px;
        height: 2px;
        width: 8px;
        background: #2f3233;
      }
    }
  }
  @media screen and (max-width: 600px) {
    width: 90vw;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.base-toast-container {
  top: 5em !important;
}

.btn {
  border: none;
  border-radius: 3px;
  font-style: italic;
  letter-spacing: 2px;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  padding: 9px 18px;
  font-size: 20px;
  text-decoration: none;
}

.base-btn-blue,
.btn-blue {
  background: #007aff;
  box-shadow: 0 -3px 0 0 #0a5eb8 inset;
  &:hover {
    background: #0561c4;
    box-shadow: 0 -3px 0 0 #094585 inset;
  }
}

.btn-orange {
  background: #faa501;
  box-shadow: 0 -3px 0 0 #e19602 inset;
  &:hover {
    background: #e19805;
    box-shadow: 0 -3px 0 0 #c18103 inset;
  }
}

.base-green-btn,
.btn-green {
  background: #28d628;
  box-shadow: 0 -3px 0 0 #22a522 inset;
  &:hover {
    background: #25aa25;
    box-shadow: 0 -3px 0 0 #1b831b inset;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
