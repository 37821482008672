.search-container {
  background: #f8f8f8;
  margin-bottom: 100px;

  .container-width {
    max-width: 1150px;
    margin: 0 auto;
    .results-container {
      display: inline-block;
      width: 67%;
      padding-right: 30px;
      .no-results {
        text-align: center;
        padding-top: 100px;
        font-size: 26px;
      }
    }
  }

  .next-page,
  .previous-page,
  .to-top {
    position: fixed;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #dae0e6;
    box-shadow: #c4c7cc 0px 0px 2px;
    transition: box-shadow 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
    padding: 5px 0px;
    width: 120px;
    text-align: center;
    cursor: pointer;

    button {
      background: #fff;
      border: none;
      outline: none;
      cursor: pointer;
    }

    &:hover {
      cursor: pointer;
      // box-shadow: 0 8px 10px rgba(133, 140, 148, 0.4), 0 0 2px #c4c7cc;
    }
  }

  .to-top {
    bottom: 200px;
    left: calc(100vw / 5);
  }

  .next-page {
    // right: 30px;
    bottom: 100px;
    left: calc(100vw / 5);
  }

  .previous-page {
    bottom: 150px;
    left: calc(100vw / 5);
  }

  .white-bg {
    display: none;
  }

  @media screen and (min-width: 1575px) {
    .to-top,
    .next-page,
    .previous-page {
      left: calc(100vw / 3.5);
    }
  }

  @media screen and (max-width: 800px) {
    .white-bg {
      display: block;
      position: fixed;
      height: 60px;
      width: 100vw;
      bottom: 69px;
      left: 0px;
      background: #0e1315;
    }
    .previous-page {
      bottom: 83px;
      width: 80px;
      left: 10px;
      background: #0e1315;
      border: none;
      button {
        color: #fff;
        background: #0e1315;
        font-weight: bold;
      }
    }

    .to-top {
      background: #0e1315;
      bottom: 83px;
      left: 50%;
      transform: translateX(-50%);
      width: 80px;
      border: none;

      button {
        color: #fff;
        background: #0e1315;
        font-weight: bold;
      }
    }

    .next-page {
      right: 10px;
      left: initial;
      bottom: 83px;
      width: 80px;
      background: #0e1315;
      border: none;
      button {
        color: #fff;
        background: #0e1315;
        font-weight: bold;
      }
    }
    .container-width {
      .results-container {
        width: 100%;
        padding: 0px 15px;
      }
    }
  }
}
