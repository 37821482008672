.image-crop-container {
  .btn-container {
    display: flex;
    position: relative;
    justify-content: flex-end;
    width: 700px;
    margin: 0 auto;
    .save-btn {
      position: absolute;
      left: 0px;
      top: 0px;
    }
    .back-btn {
      position: absolute;
      top: -30px;
      right: 0px;
    }
    .next-btn {
      position: absolute;
      top: 20px;
      right: 0px;
    }
  }

  .image-crop-tile {
    width: 700px;
    margin: 80px auto 0px auto;
    padding: 0px 30px 30px 30px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .inner-upload-container {
      padding-top: 55px;
      .tile-image-container {
        .image-upload-label {
          display: none;
        }
        input {
          display: none;
        }
      }

      .image-preview {
        display: block;
        height: 190px;
        width: auto;
        margin: 0px auto 0px auto;
        border: 1px dashed #333;
        border-radius: 3px;
        cursor: pointer;
      }

      .image-upload-button {
        width: 338px;
        height: 190px;
        margin: 0px auto 0px auto;
        background: #d8d4d4;
        border-radius: 3px;
        display: block;
        border: 1px dashed #333;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-style: italic;
        cursor: pointer;
      }
    }

    .inner-btn-container {
      display: flex;
      width: 80%;
      justify-content: center;

      .edit-crop {
        margin-left: 20px;
        &.is-disabled {
          pointer-events: none;

          color: transparent;
          background: #76b4f7;
          box-shadow: 0 -3px 0 0 #578bc2 inset;
        }
      }
    }
  }
}

.image-crop-modal {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 1100px;
  height: 100vh;
  background: transparent;
  margin-top: 5px;
  outline: none;

  .ReactCrop__image {
    max-width: 600px;
    max-height: 600px;
  }
  .close-btn {
    position: absolute;
    top: 100px;
    right: 100px;
    background: none;
    border: none;
    .close-btn-icon {
      color: #fff;
      font-size: 42px;
    }
  }
}

.image-crop-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 201;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
