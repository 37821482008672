.footer-container {
  box-sizing: border-box;
  width: 100%;
  height: 400px;
  padding: 100px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #0e1315;
  border-top: 3px solid #20212a;

  font-size: 34px;

  .inner-container {
    width: 800px;
    display: flex;
    justify-content: space-between;

    .left-side {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .site-title {
        font-family: 'Merienda', cursive;
        font-size: 28px;
        font-weight: normal;
        font-style: italic;
        color: #fff;
      }
    }

    .link-container {
      list-style: none;
      .link {
        margin-bottom: 3px;
        a {
          font-size: 20px;
          color: #fff;
          text-decoration: none;
          font-style: italic;
          padding-bottom: 3px;
          border-bottom: 1px solid #45636f;
          border-bottom: 2px solid #20212a;
          &:hover {
            color: #abc2cb;
          }
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    height: 500px;
    padding: 25px;
    align-items: flex-start;
    .inner-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .left-side {
        .site-title {
          padding: 0px;
        }
      }

      .link-container {
        list-style: none;
        padding: 0px;
        margin: 0px;
        .link {
          margin-bottom: 3px;
          a {
            font-size: 20px;
            color: #fff;
            text-decoration: none;
            font-style: italic;
            padding-bottom: 3px;
            border-bottom: 1px solid #45636f;
            border-bottom: 2px solid #20212a;
            &:hover {
              color: #abc2cb;
            }
          }
        }
      }
    }
  }
}
