.preview-container {
  min-height: 1200px;
  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .result-item-container {
      pointer-events: none;
      width: 700px;
    }

    .posting-link {
      margin: 0;
    }

    .divider {
      width: 300px;
      height: 1px;
      margin-top: 50px;
      background: rgba(0, 0, 0, 0.1);
    }

    .post-container {
      min-height: initial;
    }

    .publish-btn {
      margin-top: 50px;
    }
  }
}
