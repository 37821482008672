// @keyframes carousel-icon-entrance {
//   from {
//     transform: translate3d(90px, -90px, 0px) scale(0.2);
//   }
//   to {
//     transform: translate3d(0px, 0px, 0px) scale(1);
//   }
// }

.post-container {
  margin: 0 auto;
  background: #f8f8f8;
  min-height: calc(100vh - 55px);
  padding-top: 30px;
  margin-bottom: 100px;

  .header {
    display: flex;
    justify-content: space-between;

    .link-email-wrapper {
      display: flex;
      flex-direction: column;
      .email-wrapper,
      .link-wrapper {
        display: flex;
        align-items: center;
        text-transform: capitalize;

        .email-copy {
          margin-left: 10px;
          font-size: 20px;
          cursor: pointer;
        }
      }
      .link-wrapper {
        padding-top: 25px;
      }
    }

    .images-container {
      position: relative;
      width: 200px;
      cursor: pointer;
      .gradient {
        position: absolute;
        top: 0px;
        left: 0px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        overflow: hidden;
        width: 200px;
        height: 98%;
        background: rgba(0, 0, 0, 0.35);
        border-radius: 3px;

        svg {
          // animation: carousel-icon-entrance 450ms
          //   cubic-bezier(0.57, 0.21, 0.69, 1.25);
          font-size: 64px;
          color: #fff;
          padding-right: 10px;
        }
      }

      img {
        width: 100%;
        height: auto;
        border-radius: 3px;
        border: none;
      }
    }
  }

  .job-description {
    border: 1px solid #dae0e6;
    background-color: white;
    box-shadow: #c4c7cc 0px 0px 2px;
    border-radius: 3px;
    padding: 25px 75px 25px 75px;
    min-height: 500px;
    width: 800px;
    margin: 0 auto;

    .back-btn-container {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 30px;
      .go-back-btn {
      }
    }

    .save-btn-container {
      display: flex;
      justify-content: flex-end;
      padding-top: 10px;
      .save-btn {
      }
    }

    .description {
      padding-top: 50px;

      ul,
      ol {
        padding: 0;
        text-indent: 0;
        margin: 16px 0;
      }

      li {
        padding-top: 10px;
        padding-left: 20px;
      }
      p {
        margin: 16px 20px;
      }
    }
  }

  @media screen and (max-width: 800px) {
    padding: 0px;
    margin-bottom: 0px;
    .job-description {
      box-sizing: border-box;
      width: initial;
      padding: 25px 20px 50px 45px;
      .images-container {
        position: relative;
        width: 100%;
        cursor: pointer;
        .gradient {
          position: absolute;
          top: 0px;
          left: 0px;
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          overflow: hidden;
          width: 100%;
          height: 98%;
          background: rgba(0, 0, 0, 0.35);
          border-radius: 3px;

          svg {
            // animation: carousel-icon-entrance 450ms
            //   cubic-bezier(0.57, 0.21, 0.69, 1.25);
            font-size: 64px;
            color: #fff;
            padding-right: 10px;
          }
        }

        img {
          width: 100%;
          height: auto;
          border-radius: 3px;
          border: none;
        }
      }
    }

    .header {
      flex-direction: column;
      align-items: center;

      .images-container {
        margin-top: 25px;
      }
    }
  }
}
.Modal {
  width: 1100px;
  height: 600px;
  margin-top: 5px;
  outline: none;
  background: transparent;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 201;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
