.react-tabs {
  width: 800px;
  margin: 0 auto;

  .react-tabs__tab-list {
    width: 541px;
    margin: 0px auto;
    border-bottom: 1px solid #dae0e6;
    .react-tabs__tab {
      width: 175px;
      height: 50px;
      font-size: 24px;
      text-align: center;
      border-radius: 5px 5px 0 0;
      transition: all 0.1s;
      border-bottom: 1px solid #dae0e6;
      &:focus {
        box-shadow: none;
      }
      &:focus {
        &:after {
          background: transparent;
        }
      }
    }
  }

  .react-tabs__tab--selected {
    border-color: #dae0e6;
    background: #fff;
    color: #000;
    border-bottom: 1px solid #dae0e6;
  }

  @media screen and (max-width: 800px) {
    width: 100vw;

    .react-tabs__tab-list {
      width: 100vw;
      margin: 0px auto;
      border-bottom: 1px solid #dae0e6;
      .react-tabs__tab {
        width: 33vw;
        height: 50px;
        font-size: 16px;
        text-align: center;
        border-radius: 5px 5px 0 0;
        transition: all 0.1s;
        border-bottom: 1px solid #dae0e6;
        &:focus {
          box-shadow: none;
        }
        &:focus {
          &:after {
            background: transparent;
          }
        }
      }
    }
  }
}

.job-dashboard {
  width: 700px;
  min-height: 100vh;
  margin: 0 auto;
  .header-container {
    position: relative;
    .purchase-job-posting {
      position: absolute;
      top: 0px;
      right: 0px;
      text-decoration: none;
    }
  }

  .jobs-container {
    width: 650px;
    margin: 0 auto;
    box-shadow: none;
    border: none;
    background: transparent;

    a {
      text-decoration: none;
      color: #000;
    }

    .job-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 70px;
      border: 1px solid #dae0e6;
      border-bottom: none;
      background: #fff;
      padding: 10px 0px;
      cursor: pointer;

      &:last-of-type {
        border: 1px solid #dae0e6;
        border-bottom: 1px solid #dae0e6;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }
      &:first-of-type {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }
      &:hover {
        border: 1px solid #888;
      }

      .job-company-name {
        padding: 0px;
        padding-left: 20px;
        font-size: 24px;
        height: 100%;
        border: none;
      }

      .metrics-icon {
        margin-right: 40px;
        margin-top: 5px;
        font-size: 28px;
        color: #000;

        &:hover {
          color: #0a5eb8;
        }
      }
    }
  }
  .saved-container {
    width: 650px;
    margin: 0 auto;
    box-shadow: none;
    border: none;
    background: transparent;
    margin-bottom: 40px;

    p {
      text-align: center;
    }

    a {
      text-decoration: none;
      color: #000;
    }
    .saved-item {
      display: flex;
      cursor: pointer;
      justify-content: space-between;
      align-items: center;
      min-height: 70px;
      border: 1px solid #dae0e6;
      border-bottom: none;
      background: #fff;
      padding: 10px 0px;

      &:last-of-type {
        border: 1px solid #dae0e6;
        border-bottom: 1px solid #dae0e6;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }
      &:first-of-type {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }
      &:hover {
        border: 1px solid #999;
      }

      .lefthand-wrapper {
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        padding-bottom: 10px;

        .job-company-name {
          padding: 0px;
          padding-bottom: 20px;
          font-size: 24px;
          height: 100%;
          border: none;
        }

        .capitalize {
          text-transform: capitalize;
        }
      }

      .righthand-wrapper {
        margin-right: 20px;
        .unsave-btn {
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    width: 100vw;
    .header-container {
      .purchase-job-posting {
        position: static;
        button {
          margin: 10px auto 10px auto;
          display: block;
        }
      }
    }

    .jobs-container {
      width: 100vw;
    }
  }
}
