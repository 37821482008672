$search-height: 60px;
$search-font-size: 28px;
$search-border-radius: 2px;

.homepage-parent {
  background: #fff;
  .search-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    background-image: url('assets/hero.jpg');
    background-size: 100%;
    background-position-y: top;
    background-repeat: no-repeat;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.5);

    .home-title {
      position: absolute;
      width: 100%;
      text-align: center;
      color: #fff;
      top: 50px;
      font-size: 70px;
    }

    .mobile-nav {
      display: none;
    }

    .gradient {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background: rgba(0, 0, 0, 0.2);
    }
    .react-select-container {
      width: 300px;
      // box-shadow: rgb(196, 199, 204) 0px 0px 2px;
      .home-select__control {
        min-height: 50px;
        padding-left: 10px;
        .home-select__value-container {
          input {
            font-size: 24px !important;
            color: #000 !important;
            width: 200px !important;
          }
        }
        .home-select__placeholder {
          font-size: 24px;
          color: #1d1b1b;
        }
        .home-select__single-value {
          color: #000;
          font-size: 24px;
        }
      }
      .home-select__option {
        font-size: 24px;
      }
    }
  }

  .content-header {
    text-align: center;
    color: #333;
    font-size: 54px;
    letter-spacing: 2px;
    padding: 0;
    margin: 30px 0px 15px 0px;
  }

  .content-subheader {
    max-width: 1000px;
    margin: 0 auto 15px auto;
    line-height: 45px;
    text-align: center;
    color: #555;
    font-size: 32px;
    padding: 0px 50px;
    font-weight: lighter;
    font-style: italic;
  }

  @media screen and (max-width: 800px) {
    .search-box {
      background-size: cover;
      background-position-y: initial;
      background-repeat: initial;

      .home-title {
        top: 140px;
        font-size: 42px;
      }

      .mobile-nav {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        background: #fff;
        height: 55px;
        padding-left: 25px;
        z-index: 1000;

        .logo {
          background: url(assets/fan.png);
          background-size: cover;
          width: 55px;
          height: 45px;
        }

        .logo-text {
          font-family: 'Merienda', cursive;
          font-size: 28px;
          font-weight: normal;
          font-style: italic;
          padding-left: 20px;
        }
      }
    }

    .content-header {
      font-size: 32px;
    }

    .content-subheader {
      line-height: 26px;
      font-size: 22px;
    }
  }
}
