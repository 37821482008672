.subscription-form-container {
  margin-bottom: 100px;
  .form-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 500px;
    min-height: 290px;
    margin: 0 auto;
    padding: 25px;
    box-shadow: #212a35 0px 0px 2px;
    background: #fff;

    .stripe-icon {
      position: absolute;
      top: 10px;
      left: 30px;
      svg {
        color: #646fdf;
        font-size: 60px;
      }
    }

    .card-payments {
      position: absolute;
      top: 30px;
      right: 30px;
      width: 150px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .checkout-info {
      padding-top: 50px;
      text-transform: capitalize;
      font-size: 30px;
      font-style: italic;
      color: #2f3233;
    }

    .form-element {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 150px;
      padding-top: 40px;

      .btn-container {
        display: flex;
        justify-content: center;
        .purchase-btn {
          &.disabled {
            opacity: 0.8;
            pointer-events: none;
          }
        }
      }
    }
  }
}
