.post-job-container {
  background: #f8f8f8;
  height: calc(100vh - 55px);
  .page-header {
    text-align: center;
    margin-top: 0px;
    padding-top: 30px;
    font-style: italic;
  }
  .post-job-form {
    position: relative;
    background: #fff;
    box-shadow: #c4c7cc 0px 0px 2px;
    width: 780px;
    margin: 0 auto;
    padding: 35px 35px 35px 35px;
    position: relative;

    .separator-md {
      display: block;

      margin: 25px 0;
    }

    .separator-lg {
      display: block;
      margin: 75px 0;
    }

    .text-field-container,
    .select-field-container {
      display: inline-block;
      margin-right: 10px;

      .inner-container {
        display: flex;
        flex-direction: column;
        width: 225px;
        position: relative;
        // margin-bottom: 10px;

        .text-field-label,
        .select-field-label {
          display: flex;
          align-items: center;
          padding-bottom: 10px;
          font-weight: bold;
          .error-message {
            padding-left: 10px;
            font-size: 12px;
            font-weight: lighter;
            color: red;
          }
        }

        .text-field-input,
        .select-field {
          border-radius: 3px;
          border: 1px solid #dae0e6;
          padding: 6px 10px;
          background: #fff;
          font-size: 16px;
        }

        .select-field {
          appearance: none;
        }

        .triangle {
          position: absolute;
          top: 40px;
          right: 10px;
          width: 0;
          height: 0;
          border-top: 5px solid black;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          pointer-events: none;
        }
      }
    }
    .back-btn {
      position: absolute;
      bottom: 140px;
      right: 50px;
    }

    .save-button {
      position: absolute;
      bottom: 86px;
      right: 50px;
      background: #28d628;
      box-shadow: 0 -3px 0 0 #22a522 inset;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      color: #fff;
      font-weight: bold;
      font-style: italic;
      letter-spacing: 2px;
      &:hover {
        background: #25aa25;
        box-shadow: 0 -3px 0 0 #1b831b inset;
      }
    }
    .next-button {
      position: absolute;
      bottom: 30px;
      right: 50px;

      &.next-disabled {
        pointer-events: none;

        button {
          color: transparent;
          background: #76b4f7;
          box-shadow: 0 -3px 0 0 #578bc2 inset;
        }
      }
      button {
        display: inline-block;
        background: #007aff;
        box-shadow: 0 -3px 0 0 #0a5eb8 inset;
        border: none;
        border-radius: 3px;
        font-style: italic;
        letter-spacing: 2px;
        cursor: pointer;
        color: #fff;
        font-weight: bold;
        &:hover {
          background: #0561c4;
          box-shadow: 0 -3px 0 0 #094585 inset;
        }
      }
    }
  }
}
