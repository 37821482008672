.forgot-form {
  width: 350px;
  height: 300px;
  margin: 95px auto 100px auto;

  border: 1px solid lightgray;
  background: #fff;

  .logo {
    display: block;
    width: 70px;
    padding: 20px 0px 10px 0;
    margin: 0 auto;
  }

  label {
    display: block;
  }

  .input-container {
    position: relative;

    .email,
    .password {
      display: block;
      width: 200px;
      padding: 8px 15px;
      margin: 20px auto;
      background: rgb(250, 250, 250);
      border: 1px solid rgb(219, 219, 219);
      border-radius: 3px;
      font-size: 15px;
    }

    .password {
    }

    .error-form {
      position: absolute;
      bottom: -18px;
      left: 80px;
      color: red;
      font-size: 14px;
    }
  }

  .submit-button {
    display: block;
    width: 200px;
    margin: 40px auto 0px auto;
    padding: 8px 10px;
    border: none;
    background: #3897f0;
    cursor: pointer;
    outline: none;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    border-radius: 3px;
  }

  .login-link {
    display: block;
    margin: 0 auto;
    width: 102px;
    padding: 30px 0 0 0;
    text-decoration: none;
    font-size: 13px;
  }
}
