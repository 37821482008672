.resume-search-container {
  background: #f8f8f8;

  .container-width {
    max-width: 1150px;
    margin: 0 auto;
    .results-container {
      display: inline-block;
      width: 67%;
      padding-right: 30px;
      .no-results {
        text-align: center;
        padding-top: 100px;
        font-size: 26px;
      }
    }
  }

  .next-page,
  .previous-page,
  .to-top {
    position: fixed;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #dae0e6;
    box-shadow: #c4c7cc 0px 0px 2px;
    transition: box-shadow 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
    padding: 5px 0px;
    width: 120px;
    text-align: center;
    cursor: pointer;

    button {
      background: #fff;
      border: none;
      outline: none;
      cursor: pointer;
    }

    &:hover {
      cursor: pointer;
      // box-shadow: 0 8px 10px rgba(133, 140, 148, 0.4), 0 0 2px #c4c7cc;
    }
  }

  .to-top {
    bottom: 200px;
    left: calc(100vw / 5);
  }

  .next-page {
    // right: 30px;
    bottom: 100px;
    left: calc(100vw / 5);
  }

  .previous-page {
    bottom: 150px;
    left: calc(100vw / 5);
  }

  @media screen and (min-width: 1575px) {
    .to-top,
    .next-page,
    .previous-page {
      left: calc(100vw / 3.5);
    }
  }
  @media screen and (max-height: 816px) {
    .to-top,
    .next-page,
    .previous-page {
      left: initial;
      right: 20px;
    }
  }
}
