.image-carousel-container {
  position: relative;
  box-sizing: border-box;
  height: 90vh;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  .next-image-btn,
  .back-image-btn,
  .close-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    outline: none;

    svg {
      color: #fff;
      font-size: 32px;
    }
  }

  .next-image-btn,
  .back-image-btn {
    width: 90px;
  }

  .next-image-btn {
    top: 40vh;
    right: 0px;
  }

  .back-image-btn {
    top: 40vh;
    left: 0px;
  }

  .close-btn {
    top: 2vw;
    right: 10vw;
    svg {
      font-size: 42px;
    }
  }

  .bg-gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -10;
    background: rgba(0, 0, 0, 0.75);
    box-shadow: 0 0 300px 200px rgba(0, 0, 0, 0.75);
  }

  .carousel-image {
    max-height: 80vh;
    max-width: 90vw;
    display: none;
    border-radius: 3px;

    &.selected {
      display: block;

      // margin: 0 auto;
    }
  }

  .circle-container {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: flex-start;
    padding: 0;
    margin: 10px auto;
    // background: rgba(255, 255, 255, 0.6);
    // border-radius: 3px;
    li {
      list-style: none;
      width: 38px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .circle-button {
        padding: 0;
        outline: none;
        box-sizing: border-box;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #fff;
        border: 2px solid #fff;
        opacity: 0.8;
        transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
        margin: 0 auto;
        cursor: pointer;

        &.selected {
          width: 16px;
          height: 16px;
          background: transparent;
        }
      }
    }
  }
}
