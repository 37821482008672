.resume-container {
  position: relative;
  width: 800px;
  margin: 0 auto;
  min-height: 250px !important;

  .button-container {
    display: flex;
    justify-content: space-between;
    .invisble-input {
      display: none;
    }
  }

  // .separator {
  //   margin: 80px 0px 0px 0px;
  //   background: #dae0e6;
  //   width: 100%;
  //   height: 2px;
  // }

  .demo-wrapper {
    margin-bottom: 60px;

    .rdw-editor-toolbar {
      justify-content: flex-start !important;
      margin: 0 auto;
      padding-left: 50px;
      width: 550px;
      background: transparent;
      border: none;

      .rdw-option-wrapper {
        border: 1px solid rgb(172, 172, 172);
      }

      .rdw-inline-editor {
        // margin-top: 100px;
      }
    }
  }

  .demo-editor {
    border: 1px solid #dae0e6;
    background-color: white;
    box-shadow: #c4c7cc 0px 0px 2px;
    border-radius: 3px;
    padding: 50px 75px;
    min-height: 500px;
  }

  .btn-container {
    display: flex;
    width: auto;
    justify-content: flex-end;

    a.btn {
      margin-left: 15px;
    }
  }
}
