.upload-container {
  margin-bottom: 100px;
  .btn-container {
    display: flex;
    position: relative;
    justify-content: flex-end;
    width: 700px;
    margin: 0 auto;
    .save-btn {
      position: absolute;
      left: 0px;
      top: 0px;
    }
    .back-btn {
      position: absolute;
      top: -30px;
      right: 0px;
    }
    .next-btn {
      position: absolute;
      top: 20px;
      right: 0px;
    }
  }

  .image-upload-container {
    border: 1px solid #dae0e6;
    background-color: white;
    box-shadow: #c4c7cc 0px 0px 2px;
    border-radius: 3px;
    width: 800px;
    margin: 75px auto 0px auto;
    min-height: 500px;
    padding-top: 50px;

    .fileContainer {
      background: transparent;
      box-shadow: none;

      .deleteImage {
        background: #f04135;
        font-weight: 100;
        font-size: 20px;
        width: 25px;
        height: 25px;
        line-height: 25px;
      }
    }

    .label-image-upload {
      font-size: 18px;
    }

    .btn-image-upload {
      border: none;
      border-radius: 3px;
      font-style: italic;
      letter-spacing: 2px;
      cursor: pointer;
      color: #fff;
      font-weight: bold;
      padding: 9px 18px;
      font-size: 20px;
      text-decoration: none;
      background: #007aff;
      box-shadow: 0 -3px 0 0 #0a5eb8 inset;
      &:hover {
        background: #0561c4;
        box-shadow: 0 -3px 0 0 #094585 inset;
      }
    }
  }
}
